@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap");

@media screen and (max-width: 475px) {
  .react-datepicker-wrapper {
    display: none !important;
  }

  .react-datepicker__tab-loop {
    width: 100% !important;
  }

  .react-datepicker-popper {
    width: 100% !important;
    left: 0% !important;
    position: static !important;
    transform: none !important;
  }

  .react-datepicker {
    font-family: "Lexend Deca", sans-serif !important;
    width: 100% !important;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    border: none;
    background-color: var(--dark);
    color: var(--white);
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
    border: none;
    background-color: var(--dark);
    color: var(--white);
  }

  .react-datepicker {
    box-shadow: none;
    border: 2px solid #f3e6d8 !important;
    font-family: "Lexend Deca", sans-serif !important;
    background: #fbf7f2 !important;
  }

  .react-datepicker__header {
    width: 100% !important;
    border-bottom: none !important;
    background: #fbf7f2 !important;
    font-family: "Lexend Deca", sans-serif !important;
    color: #9b938a !important;
    font-weight: 500 !important;
  }

  .react-datepicker__navigation--previous > span::before {
    border-color: #51311f !important;
  }

  .react-datepicker__navigation--next > span::before {
    border-color: #51311f !important;
  }

  .react-datepicker__month-container {
    width: 100% !important;
    padding: 0px !important;
  }

  .react-datepicker__current-month {
    color: #5a352d !important;
    font-family: "Lexend Deca", sans-serif !important;
    font-weight: bold;
    text-transform: lowercase;
    font-size: 18px;
  }

  .react-datepicker__navigation {
    top: 20px;
    color: #5a352d !important;
  }

  .react-datepicker__day {
    font-weight: normal;
    color: #51311f;
    font-family: "Lexend Deca", sans-serif !important;
    font-weight: 400;
    font-size: 12px;
    width: 30px;
    height: 30px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day.react-datepicker__day--today {
    border-radius: 0% !important;
    font-family: "Lexend Deca", sans-serif !important;
    color: #5a352d !important;
    width: 30px;
    height: 30px;
  }

  .past-date,
  .excluded-date {
    color: #d5cabd !important;
    opacity: 1 !important;
    pointer-events: none;
  }

  .excluded-date {
    text-decoration: line-through !important;
    background-color: #fbf7f2 !important;
    color: #d5cabd !important;
  }

  .excluded-date.react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    color: #d5cabd !important;
    background-color: #fbf7f2 !important;
  }

  .react-datepicker__day.react-datepicker__day--selected,
  .react-datepicker__day.react-datepicker__day--range-start,
  .react-datepicker__day.react-datepicker__day--range-end {
    background-color: #a75833 !important;
    color: white !important;
    border-radius: 0% !important;
  }

  .react-datepicker__day.react-datepicker__day--range-start {
    border-top-left-radius: 2.5px !important;
    border-bottom-left-radius: 2.5px !important;
  }
  .react-datepicker__day.react-datepicker__day--range-end {
    border-top-right-radius: 2.5px !important;
    border-bottom-right-radius: 2.5px !important;
  }

  .react-datepicker__day.react-datepicker__day--selected:hover,
  .react-datepicker__day.react-datepicker__day--range-start:hover,
  .react-datepicker__day.react-datepicker__day--range-end:hover {
    background-color: #a75833 !important;
    color: white !important;
  }

  .react-datepicker__day.react-datepicker__day--range-start:hover {
    border-top-left-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
  }

  .react-datepicker__day--in-range {
    border-radius: 0% !important;
    background-color: #f2d8b3 !important;
    color: #5a352d !important;
  }

  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover {
    background-color: #f2d8b3 !important;
    color: #5a352d !important;
  }

  .react-datepicker__day-name {
    color: #b3886a;
    font-family: "Lexend Deca", sans-serif !important;
    font-weight: 400;
    font-size: 12px;
    text-transform: lowercase;
  }

  .react-datepicker__navigation {
    color: #5a352d !important;
  }
}

